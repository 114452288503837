.form-wrapper {
  max-width: 450px;
  margin: 0 auto;
}
.bg-primary.bg-soft {
  background-color: rgba(85, 110, 230, 0.25) !important;
}
.profile-user-wid {
  margin-top: -26px;
}
.avatar-title {
  align-items: center;
  color: #fff;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}
